import router from "./router";

// order of middlewares is important
import "./middlewares/migration";
import "./middlewares/authentication";
import "./middlewares/authorization";
import "./middlewares/skipPricingPlans";
import "./middlewares/locale";
import "./middlewares/logNavigationsToAmplitude";

export default router;
