<template>
  <v-menu :persistent="false" close-on-content-click>
    <template v-slot:activator="{ props }">
      <v-btn variant="text" tile v-bind="props">
        <app-icon size="medium" name="web" />
        <span class="pl-2 select-language-text">{{ $t("LocaleSwitcher.label") }}</span>
      </v-btn>
    </template>

    <v-list>
      <div class="text-caption px-2">
        {{ $t("LocaleSwitcher.title") }}
      </div>
      <v-list-item @click="switchLocale('en')">
        <v-list-item-title>English</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('fr')">
        <v-list-item-title>French</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('de')">
        <v-list-item-title>German</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('es')">
        <v-list-item-title>Spanish</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('hi')">
        <v-list-item-title>Hindi</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('nl')">
        <v-list-item-title>Dutch</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('pt')">
        <v-list-item-title>Portuguese</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('tr')">
        <v-list-item-title>Turkish</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('el')">
        <v-list-item-title>Greek</v-list-item-title>
      </v-list-item>
      <v-list-item @click="switchLocale('it')">
        <v-list-item-title>Italian</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {I18N_LOCAL_STORAGE_NAME} from '@/assets/js/constants';

export default {
  name: "LocaleSwitcher",
  data() {
    return {
      showLocaleDialog: false,
    };
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$amplitude.logEvent("SWITCH_LOCALE", {
          from: this.$i18n.locale,
          to: locale,
        });
        this.$i18n.locale = locale;

        localStorage.setItem(I18N_LOCAL_STORAGE_NAME, locale);
      }
      this.showLocaleDialog = false;
    },
  },
};
</script>

<style>
@media screen and (max-width: 600px) {
  .select-language-text {
    width: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
