import { defineAsyncComponent } from 'vue';
import { Role, ALL_ROLES } from "@/helpers/enums/eRole";

const Overview = defineAsyncComponent(() => import('@/pages/Overview.vue'));
const Inbox = defineAsyncComponent(() => import('@/pages/dashboard/inbox.vue'));
const KanbanBoard = defineAsyncComponent(() => import('@/pages/dashboard/KanbanBoard.vue'));
const ApplicationVue = defineAsyncComponent(() => import('@/pages/dashboard/application.vue'));
const Billing = defineAsyncComponent(() => import('@/pages/dashboard/billing.vue'));

export default [
  { path: "/", redirect: "/overview" },
  {
    path: "/overview",
    name: "overview",
    component: Overview,
    meta: { authorized: ALL_ROLES },
  },
  {
    path: "/inbox",
    name: "inbox",
    component: Inbox,
    meta: { authorized: [Role.MANAGED_USER, Role.USER] },
  },
  {
    path: "/board",
    name: "board",
    component: KanbanBoard,
    meta: { authorized: [Role.MANAGED_USER, Role.USER] },
  },
  {
    path: "/myApplications",
    name: "myApplications",
    component: ApplicationVue,
    meta: { authorized: [Role.USER, Role.MANAGED_USER] },
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: { authorized: [Role.USER] },
  },
];
